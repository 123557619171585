import { useApp } from "../../contexts/AppContext";

export default function LoadingMess() {
  const { chatbot } = useApp();
  const loaderStyle = `
    .lds-ellipsis {
      display: inline-block;
      position:absolute ;
      top:30%;
      left:45%;
      width:80px;
      height:80px;
    }
    .lds-ellipsis div {
      position: absolute;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: rgba(27, 89, 248, 1);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }
    .lds-ellipsis div:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    .lds-ellipsis div:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }
      100% {
        transform: translate(24px, 0);
      }
    }
  `;

  return (
    <div
      className="w-full h-full relative "
      // style={{ backgroundColor: "rgba(196, 196, 196, 0)" }}
    >
      <style>{loaderStyle}</style>
      <div className="lds-ellipsis">
        <div style={{ background: chatbot?.chatbotColor || "#3047EC" }}></div>
        <div style={{ background: chatbot?.chatbotColor || "#3047EC" }}></div>
        <div style={{ background: chatbot?.chatbotColor || "#3047EC" }}></div>
        <div style={{ background: chatbot?.chatbotColor || "#3047EC" }}></div>
      </div>
    </div>
  );
}
