import { useWorkflow } from '../../../contexts/WorkflowContext';
import { ChevronForwardIcon } from '../element/chevron-forward-icon';
import { findCurrentFlow } from '../../../utils/workflow'
import { IAction, IFlow } from '../../../types/workflow';
const FlowComponent = ({
  buttons,
  flows,
  setFlow,
  setNewChat,
  setPrompt,
  setMessages,
  title,
}: any) => {
  const { state: {history}, dispatch } = useWorkflow();

  const handleAction = (button: any) => {
    const [message, nextStep] = button.payload.split(':');
  
   //dispatch({ type: 'SET_CURRENT_FLOW', payload: flows[0] })
    /*if (nextStep) {
      const nextFlow = flows.find((flow: IFlow) => flow._id === nextStep)
      if (nextFlow) {
        dispatch({ type: 'SET_CURRENT_FLOW', payload: nextFlow })
      }
    } else {
      setPrompt(message);
      dispatch({ type: 'SET_CURRENT_FLOW', payload: 
      {
        _id: button.id,
        message: message,
        index: history.length,
        isPrompt: false,
        pageUrl: [],
        time: 0,
        language: 'en',
        rules: [],
        actions: []
      } })
    }*/
    setNewChat(true);
    setMessages([]);
  }

  return (
    <div className="w-full h-auto max-h-full cursor-pointer bg-white  text-[#272727] py-3 px-4 rounded-[20px]">
      <div
        id="stylescroll"
        className="w-full h-full max-h-[200px] max-md:max-h-[220px] overflow-y-auto "
      >
        <p className="font-semibold text-[18px] text-[#272727] mb-2">{title}</p>
        {buttons.map((item: any, idx: number) => (
          <div
            onClick={() => {
              handleAction(item);
            }}
            key={idx}
            className="text-[#272727] flex justify-between gap-3 items-center pr-3 "
          >
            <p className="text-[#272727] font-medium text-[16px] py-2 ">
              {item.title}
            </p>
            <ChevronForwardIcon className="w-[18px] text-gray-500" style={{}} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FlowComponent;
