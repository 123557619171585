import { useEffect, useState } from "react";
import ChatAvatar from "../../chat/chat-avatar";
import Markdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeKatex from 'rehype-katex'
import remarkMath from 'remark-math'
import { useSearchParams } from "react-router-dom";
import { checkImageExists, isImageLink, pathImage } from "../../../libs";
export const MarkdownContent = ({ message, chatbot }: { message: any, chatbot: any }) => {
  let [searchParams] = useSearchParams();
  let shopId = searchParams.get("shopId");
  const color = chatbot?.chatbotColor ? chatbot?.chatbotColor : "#3047EC";
  const type = chatbot?.type;

  const checkImage = async (href: string) => {
    if (isImageLink(href)) {
      const exists = await checkImageExists(href);
      return exists;
    }
  };

  const renderers = {
    a: (props: any) => {
      let { href, children } = props;
      if (isImageLink(href)) {
        return <img src={href} className="list-image-none mb-3" />;
      } else {
        return (
          <a
            href={href}
            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
            target={shopId ? "_parent" : "_blank"}
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
      }
    },
    table: (props: any) => <table className="table-auto mb-3">{props.children}</table>,
    thead: (props: any) => <thead className="bg-gray-50">{props.children}</thead>,
    tbody: (props: any) => <tbody className="bg-white divide-y divide-gray-200">{props.children}</tbody>,
    tr: (props: any) => <tr>{props.children}</tr>,
    th: (props: any) => (
      <th
        scope="col"
        className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {props.children}
      </th>
    ),
    td: (props: any) => (
      <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
        {props.children}
      </td>
    ),
  };

  return (
    <div
      className={`flex items-start gap-2 pe-2 ${message.role === "user" ? "justify-end ml-2" : "justify-start"
        }`}
    >
      <div
        style={{ backgroundColor: message.role === "user" ? color : "#f5f6f8" }}
        className={`flex flex-col rounded p-2 ${message.role === "user"
          ? `order-0 text-white`
          : "order-1"
          }`}
      >
        <Markdown className="break-words" remarkPlugins={[remarkGfm, remarkMath]} rehypePlugins={[rehypeKatex]} components={renderers}>
          {message.content}
        </Markdown>
        {message?.file?.uri && (
          <img
            className="w-[100px] pt-3"
            src={`${pathImage}${message.file.uri}`}
          ></img>
        )}
      </div>
      <ChatAvatar {...message} />
    </div>
  );

}