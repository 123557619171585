import { useApp } from "../../contexts/AppContext";
import { ProductCard } from "./tools/product/product-card";
import { MarkdownContent } from "./tools/mark-down";
import { SuggestedTool } from "./tools/suggested";
import { SourceLink, Sources } from "./tools/source-link";
import { JSONValue, Message } from "ai";
import { Product } from "../../types/product";
import { useWorkflow } from "../../contexts/WorkflowContext";
import { HotelCard } from "./tools/hotel/hotel-card";
import { Hotel } from "@/types/hotel";

interface ChatItemProps {
  message: any;
  handleCreateMess: any;
  setMessages: any;
  messages: Message[];
  reload: any;
  isLoading: boolean;
}

type ToolData = {
  toolCall: {
    id: string;
    name: string;
    input: {
      [key: string]: JSONValue;
    };
  };
  toolOutput: {
    output: JSONValue;
    isError: boolean;
  };
};

export default function ChatItem(props: ChatItemProps) {
  const { message, handleCreateMess, setMessages, messages, reload, isLoading } = props;
  const { chatbot } = useApp();
  const { state } = useWorkflow();

  if (!message || typeof message !== 'object' || !message.content) return;
  const getSources = (annotations: any[]) => {
    const sources: Sources = annotations.find((e) => e.type === "sources");
    return sources ? (
      <SourceLink sources={sources} chatbot={chatbot} />
    ) : null;
  };

  const getSuggested = (annotations: any[]) => {
    if(state.currentStep === 'workflow') return null;
    const suggested = annotations.find((e) => e.type === "suggested_questions");
    return suggested ? (
      <SuggestedTool
        suggested={suggested}
        handleCreateMess={handleCreateMess}
        setMessages={setMessages}
        reload={reload}
        chatbot={chatbot}
      />
    ) : null;
  };

  const getTools = (annotations: any[]) => {
    // Find the tool with the type "tools"
    const tools = annotations.find((e) => e.type === "tools");
    const toolData: ToolData = tools?.data || [];
    // Extract the toolCall name
    const toolCallName = toolData.toolCall?.name;

    // Variable to hold the output
    let output: JSONValue = [];

    // Use switch to handle different toolCall names
    switch (toolCallName) {
      case "search_travel":
        output = toolData.toolOutput?.output || [];
        return <HotelCard hotels={output as unknown as Hotel[]} />;
      case "product":
        output = toolData.toolOutput?.output || [];
        return <ProductCard products={output as unknown as Product[]} />;
      case "contact":
        output = toolData.toolOutput?.output || [];
        break;
      default:
        console.log("No matching toolCall name.");
        output = [];
    }
  };

  return (
    <div>
      {!isLoading && message.role === "assistant" && message.annotations && getTools(message.annotations)}
      <MarkdownContent message={message} chatbot={chatbot} />
      {!isLoading && message.role === "assistant" && message.annotations &&
        getSources(message.annotations)}
      {!isLoading && message.role === "assistant" && message.annotations && getSuggested(message.annotations)}
    </div>
  );
}
