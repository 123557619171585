
export const shoppings = {
    en: {
      add_to_cart: "Add to cart",
      shopping_cart: "Shopping cart",
      checkout: "Checkout",
      complete_order: "Complete order",
      total: "Total",
      full_name: "Full name",
      email: "Email",
      phone: "Phone",
      address: "Address",
    },
    ar: {
      add_to_cart: "أضف إلى السلة",
      shopping_cart: "سلة التسوق",
      checkout: "الدفع",
      complete_order: "إكمال الطلب",
      total: "المجموع",
      full_name: "الاسم الكامل",
      email: "البريد الإلكتروني",
      phone: "الهاتف",
      address: "العنوان",
    },
    de: {
      add_to_cart: "In den Warenkorb",
      shopping_cart: "Einkaufswagen",
      checkout: "Zur Kasse",
      complete_order: "Bestellung abschließen",
      total: "Gesamt",
      full_name: "Vollständiger Name",
      email: "E-Mail",
      phone: "Telefon",
      address: "Adresse",
    },
    es: {
      add_to_cart: "Añadir al carrito",
      shopping_cart: "Carrito de compras",
      checkout: "Pagar",
      complete_order: "Completar pedido",
      total: "Total",
      full_name: "Nombre completo",
      email: "Correo electrónico",
      phone: "Teléfono",
      address: "Dirección",
    },
    fr: {
      add_to_cart: "Ajouter au panier",
      shopping_cart: "Panier",
      checkout: "Paiement",
      complete_order: "Terminer la commande",
      total: "Total",
      full_name: "Nom complet",
      email: "E-mail",
      phone: "Téléphone",
      address: "Adresse",
    },
    hi: {
      add_to_cart: "कार्ट में डालें",
      shopping_cart: "शॉपिंग कार्ट",
      checkout: "चेकआउट",
      complete_order: "ऑर्डर पूरा करें",
      total: "कुल",
      full_name: "पूरा नाम",
      email: "ईमेल",
      phone: "फोन",
      address: "पता",
    },
    it: {
      add_to_cart: "Aggiungi al carrello",
      shopping_cart: "Carrello",
      checkout: "Cassa",
      complete_order: "Completa ordine",
      total: "Totale",
      full_name: "Nome completo",
      email: "Email",
      phone: "Telefono",
      address: "Indirizzo",
    },
    ja: {
      add_to_cart: "カートに追加",
      shopping_cart: "ショッピングカート",
      checkout: "チェックアウト",
      complete_order: "注文を完了する",
      total: "合計",
      full_name: "フルネーム",
      email: "メール",
      phone: "電話",
      address: "住所",
    },
    nl: {
      add_to_cart: "Toevoegen aan winkelwagen",
      shopping_cart: "Winkelwagen",
      checkout: "Afrekenen",
      complete_order: "Bestelling afronden",
      total: "Totaal",
      full_name: "Volledige naam",
      email: "E-mail",
      phone: "Telefoon",
      address: "Adres",
    },
    pt: {
      add_to_cart: "Adicionar ao carrinho",
      shopping_cart: "Carrinho de compras",
      checkout: "Finalizar compra",
      complete_order: "Completar pedido",
      total: "Total",
      full_name: "Nome completo",
      email: "Email",
      phone: "Telefone",
      address: "Endereço",
    },
    tr: {
      add_to_cart: "Sepete ekle",
      shopping_cart: "Alışveriş Sepeti",
      checkout: "Satın Al",
      complete_order: "Siparişi Tamamla",
      total: "Toplam",
      full_name: "Tam Ad",
      email: "E-posta",
      phone: "Telefon",
      address: "Adres",
    },
    ur: {
      add_to_cart: "ٹوکری میں ڈالیں",
      shopping_cart: "خریداری کی ٹوکری",
      checkout: "چیک آؤٹ",
      complete_order: "آرڈر مکمل کریں",
      total: "کل",
      full_name: "پورا نام",
      email: "ای میل",
      phone: "فون",
      address: "پتہ",
    },
    vi: {
      add_to_cart: "Thêm vào giỏ",
      shopping_cart: "Giỏ hàng",
      checkout: "Thanh toán",
      complete_order: "Hoàn tất đơn hàng",
      total: "Tổng",
      full_name: "Họ và tên",
      email: "Email",
      phone: "Điện thoại",
      address: "Địa chỉ",
    },
    zh: {
      add_to_cart: "加入购物车",
      shopping_cart: "购物车",
      checkout: "结账",
      complete_order: "完成订单",
      total: "总计",
      full_name: "全名",
      email: "电子邮件",
      phone: "电话",
      address: "地址",
    },
    "zh-CN": {
      add_to_cart: "加入购物车",
      shopping_cart: "购物车",
      checkout: "结账",
      complete_order: "完成订单",
      total: "总计",
      full_name: "全名",
      email: "电子邮件",
      phone: "电话",
      address: "地址",
    },
    "zh-TW": {
      add_to_cart: "加入購物車",
      shopping_cart: "購物車",
      checkout: "結帳",
      complete_order: "完成訂單",
      total: "總計",
      full_name: "全名",
      email: "電子郵件",
      phone: "電話",
      address: "地址",
    },
    ms: {
      add_to_cart: "Tambah ke troli",
      shopping_cart: "Troli",
      checkout: "Pembayaran",
      complete_order: "Selesaikan Pesanan",
      total: "Jumlah",
      full_name: "Nama penuh",
      email: "E-mel",
      phone: "Telefon",
      address: "Alamat",
    },
    ru: {
      add_to_cart: "Добавить в корзину",
      shopping_cart: "Корзина",
      checkout: "Оформить заказ",
      complete_order: "Завершить заказ",
      total: "Итого",
      full_name: "Полное имя",
      email: "Электронная почта",
      phone: "Телефон",
      address: "Адрес",
    },
    ko: {
      add_to_cart: "장바구니에 추가",
      shopping_cart: "쇼핑카트",
      checkout: "결제하기",
      complete_order: "주문 완료",
      total: "합계",
      full_name: "성명",
      email: "이메일",
      phone: "전화",
      address: "주소",
    },
    pl: {
      add_to_cart: "Dodaj do koszyka",
      shopping_cart: "Koszyk",
      checkout: "Zamówienie",
      complete_order: "Zakończ zamówienie",
      total: "Razem",
      full_name: "Pełne imię i nazwisko",
      email: "E-mail",
      phone: "Telefon",
      address: "Adres",
    },
    sv: {
      add_to_cart: "Lägg till i kundvagn",
      shopping_cart: "Kundvagn",
      checkout: "Kassan",
      complete_order: "Slutför beställning",
      total: "Totalt",
      full_name: "Fullständigt namn",
      email: "E-post",
      phone: "Telefon",
      address: "Adress",
    },
    no: {
      add_to_cart: "Legg til i handlekurv",
      shopping_cart: "Handlekurv",
      checkout: "Til kassen",
      complete_order: "Fullfør bestilling",
      total: "Total",
      full_name: "Fullt navn",
      email: "E-post",
      phone: "Telefon",
      address: "Adresse",
    },
    da: {
      add_to_cart: "Tilføj til kurv",
      shopping_cart: "Indkøbskurv",
      checkout: "Betaling",
      complete_order: "Fuldfør bestilling",
      total: "Total",
      full_name: "Fuldt navn",
      email: "E-mail",
      phone: "Telefon",
      address: "Adresse",
    },
    fi: {
      add_to_cart: "Lisää ostoskoriin",
      shopping_cart: "Ostoskori",
      checkout: "Kassa",
      complete_order: "Suorita tilaus",
      total: "Yhteensä",
      full_name: "Koko nimi",
      email: "Sähköposti",
      phone: "Puhelin",
      address: "Osoite",
    },
    th: {
      add_to_cart: "เพิ่มในตะกร้า",
      shopping_cart: "ตะกร้าสินค้า",
      checkout: "ชำระเงิน",
      complete_order: "สั่งซื้อเสร็จสิ้น",
      total: "ยอดรวม",
      full_name: "ชื่อเต็ม",
      email: "อีเมล",
      phone: "โทรศัพท์",
      address: "ที่อยู่",
    },
    he: {
      add_to_cart: "הוסף לעגלה",
      shopping_cart: "עגלת קניות",
      checkout: "לקופה",
      complete_order: "השלם הזמנה",
      total: "סך הכל",
      full_name: "שם מלא",
      email: "אימייל",
      phone: "טלפון",
      address: "כתובת",
    },
    id: {
      add_to_cart: "Tambahkan ke keranjang",
      shopping_cart: "Keranjang Belanja",
      checkout: "Checkout",
      complete_order: "Selesaikan Pesanan",
      total: "Total",
      full_name: "Nama Lengkap",
      email: "Email",
      phone: "Telepon",
      address: "Alamat",
    },
    el: {
      add_to_cart: "Προσθήκη στο καλάθι",
      shopping_cart: "Καλάθι αγορών",
      checkout: "Ολοκλήρωση αγοράς",
      complete_order: "Ολοκλήρωση παραγγελίας",
      total: "Σύνολο",
      full_name: "Πλήρες όνομα",
      email: "Ηλεκτρονικό ταχυδρομείο",
      phone: "Τηλέφωνο",
      address: "Διεύθυνση",
    },
    cs: {
      add_to_cart: "Přidat do košíku",
      shopping_cart: "Nákupní košík",
      checkout: "Pokladna",
      complete_order: "Dokončit objednávku",
      total: "Celkem",
      full_name: "Celé jméno",
      email: "E-mail",
      phone: "Telefon",
      address: "Adresa",
    },
  };