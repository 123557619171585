import React, { createContext, useState, useContext, ReactNode } from "react";
import { ChatbotType } from "../types/chatbot";

// Define the shape of the context
interface AppContextType {
  _id?: string;
  setBot: (bot: ChatbotType) => void;
  setFlow: (data: any) => void;
  changeFlowWait: (data: any) => void;
  changeFlowUser: (data: any) => void;
  setInbox: (id: string) => void;
  setCurrentPage: (p: string) => void;
  setUuid: (id: string) => void;
  setNotifi: any;
  setIschat: any;
  chatbot: ChatbotType;
  inboxId: string;
  page: string;
  flow: any;
  flowWait: any;
  flowUser: any;
  notifi: boolean;
  ischat: boolean;
  uuid: string;
}

// Create context with default values
const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

// Provider component
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [inboxId, setInboxId] = useState<string>("");
  const [page, setPage] = useState<string>("init");
  const [flow, setflow] = useState<any>();
  const [flowWait, setFlowWait] = useState<any>();
  const [flowUser, setFlowUser] = useState<any>();
  const [notifi, setNotifi] = useState(false);
  const [ischat, setIschat] = useState(false);
  const [uuid, setUuidId] = useState("");

  // Initialize with a default IChat object
  const [chatbot, setChatbot] = useState<ChatbotType>({
    _id: "",
    chatModel: "gpt-3.5",
    chatbotColor: "#3461ee",
    chatbotIcon: "",
    flows: [],
    isAgent: true,
    isLead: true,
    language: "en",
    menu: [],
    links: [],
    name: "TaggoAI",
    pageId: "",
    oaId: "",
    prompts: [],
    textPlaceholder: "Type a message",
    translate: [],
    usageMessage: 0,
    welcomeMessage: "I'm your AI assistant, how can I help you?",
    whatsappNumber: "",
    type: "chatbot",
  });

  const setBot = (bot: ChatbotType) => {
    setChatbot(bot);
  };
  const setFlow = (data: any) => {
    setflow(data);
  };
  const changeFlowWait = (data: any) => {
    setFlowWait(data);
  };
  const changeFlowUser = (data: any) => {
    setFlowUser(data);
  };

  const setInbox = (id: string) => {
    setInboxId(id);
  };

  const setCurrentPage = (p: string) => {
    setPage(p);
  };

  const setUuid = (id: string) => {
    setUuidId(id);
  };

  return (
    <AppContext.Provider
      value={{
        chatbot,
        page,
        setCurrentPage,
        inboxId,
        setBot,
        setInbox,
        setFlow,
        flow,
        flowWait,
        changeFlowWait,
        flowUser,
        changeFlowUser,
        notifi,
        setNotifi,
        ischat,
        setIschat,
        setUuid,
        uuid,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use language context
export const useApp = (): AppContextType => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
};
