import { useTranslate } from '../../../contexts/TranslateContext';
import { useWorkflow } from "../../../contexts/WorkflowContext";
import { ReactComponent as ChevronForwardIcon } from '../../icons/chevronforward.svg';
const PromptComponent = ({
  chatbot,
  setNewChat,
  setPrompt,
  setFlow,
  setMessages,
}) => {
  const { translations } = useTranslate();
  const { dispatch } = useWorkflow();
  return (
    <div className="w-full h-auto max-h-full cursor-pointer bg-white  text-[#272727] py-3 px-4 rounded-[20px]">
      <div
        id="stylescroll"
        className="w-full h-full max-h-[200px] max-md:max-h-[220px] overflow-y-auto pb-3"
      >
        <p className="font-semibold text-[18px] text-[#272727]">
        {translations?.find_for_help}
        </p>
        {chatbot &&
          chatbot?.prompts.length > 0 &&
          chatbot?.prompts.map((item, idx) => (
            <div
              key={idx}
              onClick={() => {
                setNewChat(true);
                setFlow(undefined);
                setMessages([]);
                if (item.text) {
                  setPrompt(item.text);
                } else {
                  setPrompt(item.prompt);
                }
                dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' })
              }}
              className="text-[666666] flex justify-between gap-3 items-center pr-3 "
            >
              <p className="text-[666666] font-medium text-[16px] py-[7px]">
                {item.prompt}
              </p>
              <ChevronForwardIcon className="w-[18px] text-gray-400"/>
            </div>
          ))}
      </div>
    </div>
  );
};
export default PromptComponent;
