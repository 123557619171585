import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { isAppDomain, appURL, poweredBy, pathImage, socketURL } from "../libs";
import io from "socket.io-client";
import { useApp } from "../contexts/AppContext";
import { useShoppingContext } from "../contexts/ShoppingContext";

import { isMobile } from "react-device-detect";
import { Button } from "./ui/button"
import { ChatIcon } from "./ui/element/chat-icon";
import { ChatbubblesIcon } from "./ui/element/chatbubbles-icon";
import { ChevronBackIcon } from "./ui/element/chevronback-icon";
import { ChatbotType } from "@/types/chatbot";
import Chat from "./Chat";
import { useTranslate } from "../contexts/TranslateContext";

interface ChatBotInitProps {
  dataBot: ChatbotType;
  botId: string;
  flows: any;
  uuid: string;
  lead: any;
  setLead: any;
  setShow: any;
  start: string;
  isIframe?: boolean;
}

export default function Assistant({
  dataBot,
  botId,
  flows,
  uuid,
  lead,
  setLead,
  setShow,
  start,
  isIframe,
}: ChatBotInitProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const ws = useRef<any>(null);
  const [closeMess, setCloseMess] = useState(false);
  const [messages, setMessages] = useState<any>();
  const [prompt, setPrompt] = useState<any>();
  const [checkContinue, setContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false)
  const { state: { cartItems, product } } = useShoppingContext()
  const { translations } = useTranslate();
  const {
    chatbot,
    setInbox,
    inboxId,
    setCurrentPage,
    page,
    flow,
    setFlow,
    setNotifi,
    ischat,
  } = useApp();
  useEffect(() => {
    handleMessages();
    setCurrentPage(start);
  }, []);
  const handleMessages = async () => {
    setInbox("");
    setContinue(true);
    setLoading(true);
    if (uuid && botId) {
      await axios
        .get(`${appURL}/api/inbox?botId=${botId}&uuid=${uuid}`)
        .then((res) => {
          const sortdata = res.data.sort(
            (a: any, b: any) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          setMessages(sortdata);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  const hanldeClickBack = () => {
    if (page == "message") {
      setCurrentPage("messages");
    } else {
      setCurrentPage(start || "home");
    }
    setInbox("");
  };
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Playback failed:", error);
      });
    }
  };

  useEffect(() => {
    const socket = io(socketURL);
    socket.on("connect", () => {
      // console.log("Is connected");
    });

    socket.on(`message ${botId} ${uuid}`, (message: any) => {
      if (message.type === "agent") {
        setNotifi(true);
        playAudio();
      }
    });
    socket.on("connect_error", (err: any) => {
      //console.log(`connect_error due to ${err.message}`);
    });
    return () => {
      socket.disconnect();
    };
  }, [botId, uuid, messages]);

  const color = chatbot?.chatbotColor ? chatbot?.chatbotColor : "#3047EC";

  return (
    <div
      style={{
        width: isMobile ? "100%" : isIframe ? 390 : "960px",
        height: isMobile ? "100%" : isIframe ? 570 : "90%",
        zIndex: 999999,
        background:
          page == "home"
            ? `linear-gradient(to bottom, ${color} 2%, #FFFFFF 57%)`
            : "",
        backdropFilter: "blur(10px)",
      }}
      className={`relative max-w-5xl mx-auto ${isAppDomain ? "w-screen h-full" : isMobile ? "w-full h-full" : " "
        }    overflow-hidden bg-white shadow ${page === "home" ? "" : "border"} rounded-2xl text-[14px]`}
    >
      <audio ref={audioRef}>
        <source src="/mp3/notification.mp3" type="audio/mpeg" />
      </audio>
      <div
        className={`w-full h-[10%] flex justify-between items-center px-4 relative`}
        style={{
          backgroundColor: "",
        }}
      >
        {page != "home" && page != "init" ? (
          <div className="text-[18px] font-bold flex items-center justify-between gap-2 w-full relative max-md:pr-4">
            <div className="flex items-center gap2">
              <div onClick={hanldeClickBack} className="cursor-pointer">
                <ChevronBackIcon className={`w-[22px] text-black`} style={{}} />
              </div>
              <p className={"text-black pl-2"}>
                {page == "messages" ? "Messages" : dataBot?.name ?? "TaggoAI"}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex gap-3 items-center justify-between w-full">
            <div className="flex gap-3 items-center">
              {chatbot?.chatbotIcon && !imageError ? (
                <img
                  src={`${pathImage}${chatbot.chatbotIcon}`}
                  className="w-[32px] h-[32px]"
                  onError={() => setImageError(true)}
                  alt="chatbot icon"
                />
              ) : (
                <ChatIcon
                  style={{ color: color || "#333" }}
                  className="w-[32px] h-[32px]"
                />
              )}
              <div className={`text-[18px] font-bold text-black`}>
                <p>{dataBot?.name ?? "TaggoAI"}</p>
              </div>
            </div>
            <div className="flex items-center">
              {page == "init" && (
                <Button
                  size="icon"
                  variant="ghost"
                  className={`text-black relative w-5 h-5 p-0`}
                  onClick={() => setCurrentPage("messages")}
                >
                  <ChatbubblesIcon
                    style={{ color: "#222" }}
                    className="w-5 h-5"
                  />
                </Button>
              )}
            </div>
          </div>
        )}
      </div>

      <div className={`w-full h-[90%] ${page == "messages" && ""}`}>
        <Chat
          botId={botId}
          dataBot={chatbot}
          closeMess={closeMess}
          setCloseMess={setCloseMess}
          uuid={uuid}
          lead={lead}
          setLead={setLead}
          messagesold={messages}
          flows={flows}
          newchat
          prompt={prompt}
          newFlow={flow}
          checkContinue={checkContinue}
          loading={loading}
          model={"gpt-4"}
          sendAgent={() => { }}
        />
      </div>

    </div>
  );
}
