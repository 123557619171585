import { chatbots } from "./chatbots";
import { shoppings } from "./shopping";

// Define a type for language codes
export type LanguageCode =
  | 'en'
  | 'ar'
  | 'de'
  | 'es'
  | 'fr'
  | 'hi'
  | 'it'
  | 'ja'
  | 'nl'
  | 'pt'
  | 'tr'
  | 'ur'
  | 'vi'
  | 'zh'
  | 'zh-CN'
  | 'zh-TW'
  | 'ms'
  | 'ru'  // Russian
  | 'ko'  // Korean
  | 'pl'  // Polish
  | 'sv'  // Swedish
  | 'no'  // Norwegian
  | 'da'  // Danish
  | 'fi'  // Finnish
  | 'th'  // Thai
  | 'he'  // Hebrew
  | 'id'  // Indonesian
  | 'el'  // Greek
  | 'cs'; // Czech;
export const validLanguages: LanguageCode[] = [
  'en', 'ar', 'de', 'es', 'fr', 'hi', 'it', 'ja', 'nl', 'pt', 'tr', 'ur',
  'vi', 'zh', 'zh-CN', 'zh-TW', 'ms', 'ru', 'ko', 'pl', 'sv', 'no', 'da',
  'fi', 'th', 'he', 'id', 'el', 'cs'
];

function deepMerge(
  target: Record<string, any>,
  ...sources: Record<string, any>[]
): Record<string, any> {
  sources.forEach((source) => {
    Object.keys(source).forEach((key) => {
      if (
        source[key] &&
        typeof source[key] === "object" &&
        !Array.isArray(source[key])
      ) {
        target[key] = deepMerge(target[key] || {}, source[key]);
      } else {
        target[key] = source[key];
      }
    });
  });
  return target;
}

export const translations: Record<LanguageCode, Record<string, string>> = deepMerge(
  {},
  chatbots,
  shoppings
);