import { useState } from "react";
import { startRecording, stopRecordingAndProcess } from "../../libs/voiceUtils";
import { transcribeAudio } from "../../libs/chatUtils";
import { MicIcon } from '../ui/element/mic-icon';

export default function ChatVoice({ setMessageText }: { setMessageText: (t: string) => void }) {
  const [isPressed, setIsPressed] = useState(false); // State to track button press

  const handleInteractionStart = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault(); // This will prevent the default browser behavior on touch
    setIsPressed(true); // Update state to true when button is pressed

    startRecording();

  };

  const handleInteractionEnd = async (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
    event.preventDefault(); // This will prevent the default browser behavior on touch
    setIsPressed(false); // Update state to false when button is released

    const audioData = await stopRecordingAndProcess();
    // Pass setIsLoading to manage loading state directly within transcribeAndChat and subsequently in streamAudio
    const text = await transcribeAudio(audioData);
    setMessageText(text || "")

  };

  setTimeout(() => {
    const asyncInit = async () => {
      setIsPressed(false);
      const audioData = await stopRecordingAndProcess();
      const text = await transcribeAudio(audioData);
      setMessageText(text || "")
    }
    asyncInit()
  }, 9000); // Set timeout to stop recording after 9 seconds

  return (
    <>
      <button
        onClick={isPressed ? handleInteractionEnd : handleInteractionStart}
        className={`unselectable text-gray-500`}
        style={{ userSelect: 'none', WebkitUserSelect: 'none', transition: 'transform 0.2s' }}
      >
        <MicIcon className="w-5 h-5" style={{
          transform: isPressed ? 'scale(1.2)' : 'scale(1)',
          transition: 'transform 0.2s',
          color: isPressed ? 'blue' : 'gray'
        }} />
      </button>
    </>
  );
}
