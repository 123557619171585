import React, { useEffect, useState } from 'react'
import { useChat, Message } from 'ai/react'
import { useWorkflow } from '../../contexts/WorkflowContext'
import type { IFlow, IAction } from '../../types/workflow'
import { WorkflowStep } from './workflow-step'
import { findFlowByNextStep } from '../../utils/workflow'
import { useApp } from '../../contexts/AppContext'
import { appChatURL, generateString } from '../../libs'
import ChatItem from './chat-item'
import { useTranslate } from '../../contexts/TranslateContext'
import { AIThinkingIndicator } from "../ui/thinking-indicator"
import ChatAvatar from './chat-avatar'

interface WorkflowEngineProps {
  initialFlows: IFlow[];
  handleCreateMess: (isAgent: boolean, type: string, prompt: string) => void;
  setMessages: (e: any) => void;
  onReload: () => void;
  isLoading: boolean;
  messages: Message[];
}

export function WorkflowEngine({ initialFlows, handleCreateMess, setMessages, onReload, messages, isLoading }: WorkflowEngineProps) {
  const { state: { flows, history, currentStep, currentFlow }, dispatch } = useWorkflow();
  const [isWaitingForInput, setIsWaitingForInput] = useState(false)
  const [chatMessages, setChatMessages] = useState<Message[]>([])
  const { chatbot } = useApp();
  const { translations } = useTranslate();
  // const { messages, append, reload, stop, isLoading: isChatLoading, input, setInput } = useChat({
  //   api: `${appChatURL}/api/assistant/${chatbot._id}`,
  //   onResponse: (response) => {
  //     if (response.status === 200) {
  //       setIsLoading(false)
  //       dispatch({ type: 'SET_CURRENT_STEP', payload: 'workflow' })
  //     }
  //   },
  // })

  useEffect(() => {
      dispatch({ type: 'SET_FLOWS', payload: initialFlows })
  }, [initialFlows, dispatch])

  const handleAction = async (action: IAction) => {
    // setIsLoading(true)
    const message = action.message || action.name || ''
    if (action.next_step && flows) {
      const nextFlow = findFlowByNextStep(flows, action.next_step);
      if (nextFlow) {
        if (nextFlow.isPrompt) {
          await callChatbot(message);
          //Wait for chat response then display next step
          if (!isLoading) {
            callNextStep(nextFlow)
          }
        } else {
          //Display Next Step
          setChatMessages((messages) => [
            ...messages,
            {
              id: nextFlow._id,
              content: nextFlow.message || nextFlow.name || "",
              role: 'assistant',
            },
          ]);
          callNextStep(nextFlow)
        }
      }
    } else {
      // If there's no next_step, call the OpenAI chat
      await callChatbot(message)
    }

    dispatch({ type: 'SET_CURRENT_STEP', payload: 'workflow' })
  }

  const callNextStep = (nextFlow: IFlow) => {
    dispatch({ type: 'ADD_TO_HISTORY', payload: currentFlow! })
    dispatch({ type: 'SET_CURRENT_FLOW', payload: nextFlow })
  }

  useEffect(() => {
    const next_action = currentFlow?.actions.find(action => action.type === "next");
    if (next_action) {
      setIsWaitingForInput(true)
    } else {
      setIsWaitingForInput(false)
    }
  }, [currentFlow])


  useEffect(() => {
    if (isWaitingForInput && messages.length > 0 && !isLoading) {
      const next_action = currentFlow?.actions.find(action => action.type === "next");
      const nextFlow = findFlowByNextStep(flows, next_action?.next_step || '');
      if (nextFlow) {
        setChatMessages((messages) => [
          ...messages,
          {
            id: nextFlow._id,
            content: nextFlow.message || nextFlow.name || "",
            role: 'user',
          },
        ]);
        callNextStep(nextFlow)
        dispatch({ type: 'SET_CURRENT_STEP', payload: 'workflow' })
      }
    }
  }, [messages, isLoading])

  const callChatbot = async (message: string) => {
    // const response = await append({
    //   role: 'user',
    //   content: message,
    // })

    const finalMessages = chatMessages.filter(
      (item: Message) => item.content && item.content.trim() !== ""
    );

    finalMessages.push({
      role: "user",
      content: message,
      id: generateString(8),
    })

    setMessages(finalMessages);
    handleCreateMess(true, message, "user");
    onReload();

    console.log("message", message);

    if (messages) {
      dispatch({ type: 'ADD_TO_HISTORY', payload: currentFlow! })
      // dispatch({
      //   type: 'SET_CURRENT_FLOW',
      //   payload: {
      //     _id: 'chat-response-' + Date.now(),
      //     message: translations.send_us_a_message,
      //     index: history.length,
      //     isPrompt: false,
      //     pageUrl: [],
      //     time: 0,
      //     language: chatbot.language || 'en',
      //     rules: [],
      //     actions: [
      //       {
      //         name: translations.thathelped,
      //         type: 'button',
      //         message: translations.thathelped,
      //         data: null,
      //         index: 0,
      //         _id: 'continue-action-1'
      //       },
      //       {
      //         name: translations.talkToAPerson,
      //         type: 'button',
      //         message: translations.talkToAPerson,
      //         data: null,
      //         index: 0,
      //         _id: 'continue-action-1'
      //       }
      //     ]
      //   }
      // })
    }

    dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' })
  }

  return (
    <div id="stylescroll" className="h-full overflow-auto">
      <div className="flex flex-col space-y-4 p-3">
        {/* {state.history.map((flow, index) => (
          <WorkflowStep
            key={`${flow._id}-${index}`}
            flow={flow}
            onAction={handleAction}
            isHistory={true}
          />
        ))} */}
        {messages.map((message, index) => (
          <ChatItem
            key={message.id}
            message={message}
            messages={messages}
            setMessages={setMessages}
            handleCreateMess={handleCreateMess}
            reload={onReload}
            isLoading={isLoading}
          />
        ))}

        {(currentFlow && !isLoading) && (
          <WorkflowStep
            flow={currentFlow}
            onAction={handleAction}
            isHistory={false}
          />
        )}
        {(isLoading) && (
          <div className="flex items-start items-center gap-3">
            <div className="h-6 w-6 flex items-center justify-center text-white shrink-0">
              <ChatAvatar role='bot' />
            </div>
            <div className="bg-white p-3 rounded-lg">
              <AIThinkingIndicator />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}


