import { ChatbotType } from "../../../types/chatbot";
export type Sources = {
    data: {
        nodes: any[];
    };
};
export const SourceLink = ({ sources, chatbot }: { sources: Sources, chatbot: ChatbotType }) => {
    const nodes = sources?.data?.nodes || [];
    const nodeData = nodes[0];
    const cleanUrl = nodeData?.metadata?.file_name
        ? nodeData.metadata.file_name.replace(/\.txt$/, '')
        : null;
    const dataUrl = chatbot.links?.find(
        (e: any) => e.uri === cleanUrl || e.uri === nodeData?.metadata?.file_name
    );
    return (
        <div className="flex flex-col items-end text-sm mr-2 mt-1">
            <div className="flex items-center gap-1 mb-1">
                {dataUrl && (
                    <a
                        target="_blank"
                        href={dataUrl.url}
                        className="flex items-center gap-1"
                    >
                        <img src="./icon/link-outline.svg" width={22} height={22} />
                        <div className="max-w-[150px] text-[12px] rounded-md bg-gray-100 p-1 truncate">
                            {dataUrl.title || ""}
                        </div>
                    </a>
                )}
            </div>
        </div>
    );
};

/* node?.metadata?.file_name && (
                                <div className="flex items-center gap-1">
                                    <span className="text-sm">{translations?.source}:</span>
                                    <span className="bg-gray-100 p-1">
                                        {node.metadata.file_name.slice(-20)}
                                    </span>
                                </div>*/