import React, { useState, useEffect, useRef } from 'react';
import { Button } from "../ui/button";
import { MicIcon } from '../ui/element/mic-icon';

interface VoiceInputButtonProps {
  onComplete: (transcript: string) => void;
  language: 'en-US' | 'vi-VN';
}

export function VoiceInputButton({ onComplete, language }: VoiceInputButtonProps) {
  const [isListening, setIsListening] = useState(false);
  const [text, setText] = useState("");
  const recognitionRef = useRef<SpeechRecognition | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onTranscript = (t: string) => {
    setText(t);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

      recognitionRef.current = new SpeechRecognition();
      if (recognitionRef.current) {
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = language;

        recognitionRef.current.onresult = (event) => {
          const transcript = Array.from(event.results)
            .map(result => result[0].transcript)
            .join('');
          onTranscript(transcript);
        };
      }
    }
  }, [language]);

  const toggleListening = () => {
    if (recognitionRef.current) {
      if (isListening) {
        recognitionRef.current.stop();
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
        if (onComplete) onComplete(text);
      } else {
        recognitionRef.current.start();
        timeoutRef.current = setTimeout(() => {
          recognitionRef.current?.stop();
          setIsListening(false);
          if (onComplete) onComplete(text);
        }, 7000); // Stop after 7 seconds
      }
      setIsListening(!isListening);
    }
  };

  return (
    <div className={`unselectable text-gray-500 p-0 border-0 cursor-pointer`}
      style={{ userSelect: 'none', WebkitUserSelect: 'none', transition: 'transform 0.2s' }} onClick={toggleListening}>
      <MicIcon className="w-5 h-5" style={{
        transform: isListening ? 'scale(1.2)' : 'scale(1)',
        transition: 'transform 0.2s',
        color: isListening ? 'blue' : 'gray'
      }} />
    </div>
  );
}
