import type { IAction, IFlow } from '../types/workflow'

interface ButtonBase {
  id: string;
  title: string;
}

interface PostbackButton extends ButtonBase {
  type: "postback";
  payload: string;
}

interface WebUrlButton extends ButtonBase {
  type: "web_url";
  url: string;
}

interface PhoneButton extends ButtonBase {
  type: "phone_number";
  payload: string;
}

type Button = PostbackButton | WebUrlButton | PhoneButton;
type INext = {
  type: "next";
  title: string;
  payload: string;
}
/**
 * Creates buttons array from actions
 */
export const getButtonsFromActions = (actions: IAction[]): Button[] => {
  if (!actions?.length) return [];

  return actions
    .map(action => {
      switch (action.type) {
        case "button":
          return {
            id: action._id,
            type: "postback",
            title: action.name,
            payload: `${action.message || action.name}:${action.next_step ?? "none"}`
          };
        case "button:link":
          return {
            id: action._id,
            type: "web_url",
            title: action.name,
            url: action.data?.[0] || ''
          };
        case "button:phone":
          return {
            id: action._id,
            type: "phone_number",
            title: action.name,
            payload: action.data?.[0] || ''
          };
        default:
          return null;
      }
    })
    .filter((button): button is Button =>
      button !== null && button !== undefined
    )
    .slice(0, 6); // Facebook limits to 3 buttons
};

export const getNextFromActions = (actions: IAction[]): IAction | undefined => {
  if (!actions || actions.length === 0) return undefined;

  const nextAction = actions.find(action => action.type === "next");

  if (nextAction && nextAction.next_step) {
    return {
      ...nextAction,
      message: "Next",
    };
  }

  return undefined;
};

interface ImageData {
  title?: string;
  image_url: string;
}

/**
 * Extracts image data from actions
 */
export const getImageFromActions = (actions: IAction[]): ImageData | null => {
  const imageAction = actions?.find(action => action.type === "image");
  if (!imageAction?.data?.[0]) return null;

  return {
    title: imageAction.name?.slice(0, 20),
    image_url: imageAction.data[0]
  };
};

/**
 * Finds a flow by its next step ID
 */
export function findFlowByNextStep(flows: IFlow[], nextStepId: string): IFlow | undefined {
  return flows.find(flow => flow._id === nextStepId);
}

/**
 * Finds the current flow based on the next step ID
 */
export function findCurrentFlow(flows: IFlow[], nextStepId: string): IFlow | undefined {
  return flows.find(flow =>
    flow.actions.some(option => option?.next_step?.includes(nextStepId))
  );
}