import React, { createContext, useState, useContext, ReactNode } from "react";
import { translations, LanguageCode } from "../libs/translations";

// Define the shape of the context
interface TranslateContextType {
  language: LanguageCode;
  changeLanguage: (lang: LanguageCode) => void;
  translations: Record<string, string>;
}

// Create context with default values
const TranslateContext = createContext<TranslateContextType | undefined>(undefined);

interface TranslateProviderProps {
  children: ReactNode;
}

// Provider component
export const TranslateProvider: React.FC<TranslateProviderProps> = ({ children }) => {
  const [language, setLanguage] = useState<LanguageCode>("en");

  // Function to change language
  const changeLanguage = (lang: LanguageCode) => {
    setLanguage(lang);
  };

  return (
    <TranslateContext.Provider
      value={{
        language,
        changeLanguage,
        translations: translations[language],
      }}
    >
      {children}
    </TranslateContext.Provider>
  );
};

// Custom hook to use language context
export const useTranslate = (): TranslateContextType => {
  const context = useContext(TranslateContext);
  if (!context) {
    throw new Error("useTranslate must be used within a TranslateProvider");
  }
  return context;
};
