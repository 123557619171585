import { Message, useChat } from "ai/react";
import { useEffect, useMemo, useState, useRef } from "react";
import { insertDataIntoMessages } from "./transform";
import { ChatInput } from "./chat/chat-input";
import { ChatMessages } from "./chat/chat-messages";
import { useApp } from "../contexts/AppContext";
import ListInboxComponent from "./ui/element/list-inbox";
import LoadingMess from "./ui/LoadingMess";
import io from "socket.io-client";

import {
  firstWords,
  socketURL,
  appChatURL,
  appURL,
  isMobile,
  generateString,
  accessToken,
} from "../libs";
import axios from "axios";
import { ChatbotType } from "@/types/chatbot";
import { WorkflowEngine } from "./chat/workflow-engine";
interface ChatSectionProps {
  botId: string;
  dataBot: ChatbotType;
  model: any;
  closeMess: any;
  setCloseMess: any;
  uuid: string;
  setLead: any;
  lead: any;
  sendAgent: () => void;
  messagesold: any;
  flows: any;
  newchat: boolean;
  prompt: any;
  newFlow: any;
  checkContinue: any;
  loading: any;
}
let Id: string | undefined;
export default function ChatSection({
  botId,
  dataBot,
  model,
  closeMess,
  setCloseMess,
  uuid,
  lead,
  sendAgent,
  messagesold,
  flows,
  newchat,
  prompt,
  newFlow,
  checkContinue,
  loading,
}: ChatSectionProps) {
  const [file, setFile] = useState<File>();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [filePath, setFilePath] = useState();

  const [isAgent, setIsAgent] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [checkLead, setcheckLead] = useState(false);
  // const [flow, setFlow] = useState();
  const [messResult, setMessResult] = useState<Message[]>([]);
  const {
    setInbox,
    inboxId,
    page,
    chatbot,
    setCurrentPage,
    flow,
    setFlow,
    setNotifi,
    ischat,
    setIschat,
  } = useApp();
  const [listMessages, setListMessages] = useState([]);

  useEffect(() => {
    if (inboxId && inboxId !== Id) {
      Id = inboxId;
    }
    if (inboxId) {
      hanldeGetMessById(inboxId);
    }
  }, [inboxId]);

  useEffect(() => {
    Id = undefined;
    setNotifi(false);
    setIschat(true);
  }, []);

  const hanldeGetMessById = async (id: string) => {
    await axios
      .get(`${appURL}/api/inbox/messages?inboxId=${id}`)
      .then((res) => {
        const sortdata = res.data.sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
        const newData = sortdata.map((item: any) => ({
          id: item._id,
          role: item.type,
          content: item.message,
          file: item?.file,
        }));
        setListMessages(newData);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const hanldeGetmess = (mess: any) => {
    setIsAgent(false);
    setIsMenu(true);
    if (mess.content) {
      handleCreateMess(false, mess.content, mess.role);
    } else {
      append({
        content:
          "Please wait a few minutes to receive a response from our support staff",
        id: generateString(8),
        role: "assistant",
      });
    }
  };

  const {
    messages,
    input,
    isLoading,
    handleSubmit,
    handleInputChange,
    reload,
    setMessages,
    stop,
    data,
    setInput,
    append,
  } = useChat({
    api: `${appChatURL}/api/assistant/${botId}`,
    body: {
      data: {
        // fileUri: filePath ? `${pathImage}${filePath}` : null,
        botId,
        isAgent: dataBot?.isAgent,
        uuid,
        leadId: lead?._id,
        isWebchat: true,
        //model: 'gpt-4'
      },
    },
    onFinish: hanldeGetmess,
    headers: {
      "x-api-key": accessToken || "",
    },
  });
  useEffect(() => {
    if (listMessages && listMessages?.length > 0) {
      setMessages(listMessages);
    }
  }, [listMessages]);

  useEffect(() => {
    if (closeMess) {
      setMessages([]);
      setCloseMess(false);
    }
  }, [closeMess]);
  useEffect(() => {
    if (!lead && dataBot?.isLead && messages.length === 4) {
      setcheckLead(true);
    }
  }, [lead, messages]);

  useEffect(() => {
    if (prompt && newchat && messages.length === 0) {
      const message: Message[] = [
        { content: prompt, id: generateString(8), role: "user" },
      ];
      setMessages(message);
      reload();
      handleCreateMess(true, prompt, "user");
    }
  }, []);

  const transformedMessages = useMemo(() => {
    return insertDataIntoMessages(messages, data);
  }, [messages, data]);


  useEffect(() => {
    const socket = io(socketURL);
    socket.on("connect", () => {

    });

    socket.on(`message ${botId} ${uuid}`, (message) => {
      if (message.type === "agent") {
        let Array = messages;
        const messAssistant: any = {
          content: message.message,
          id: generateString(8),
          role: "agent",
          data: message.file
            ? {
              type: message.file.type,
              image_url: {
                url: `${appURL}/${message.file.uri}`,
              },
            }
            : null,
        };
        Array.push(messAssistant);
        setMessages(Array);
        reload();
        playAudio();
      }
    });
    socket.on("connect_error", (err) => {
      //console.log(`connect_error due to ${err.message}`);
    });
    return () => {
      socket.disconnect();
    };
  }, [botId, uuid, messages]);
  const handleCreateMess = async (isUser: boolean, mess: string, role: string) => {
    const fileAttach = {
      type: file ? file.type : "",
      name: file ? file.name : "",
      uri: filePath ? `${filePath}` : "",
    };
    const body = {
      name: firstWords(mess),
      message: mess,
      botId,
      type: role,
      // read: true,
      uuid,
      file: filePath && role === "user" ? fileAttach : null,
      leadId: lead?._id,
      inboxId: Id,
      userId: dataBot?.userId,
    };
    //create new message.
    if (role == "user" || (role == "assistant" && Id)) {
      await axios
        .post(`${appURL}/api/inbox`, body)
        .then((res) => {
          if (!Id) {
            setInbox(res.data?.inboxId);
            Id = res.data?.inboxId;
          }
          setFilePath(undefined);
          setFile(undefined);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current?.play().catch((error: any) => {
        console.error("Playback failed:", error);
      });
    }
  };

  return (
    <div
      className={`max-w-5xl max-md:max-w-[100vw] w-full h-full overflow-hidden `}
    >
      <audio ref={audioRef}>
        <source src="/mp3/notification.mp3" type="audio/mpeg" />
      </audio>
      {page == "messages" ? (
        loading ? (
          <LoadingMess />
        ) : (
          <div className="w-full h-full flex flex-col">
            {messagesold && messagesold.length > 0 ? (
              <ListInboxComponent inboxs={messagesold} />
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="">No conversations yet</p>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="w-full h-full flex flex-col">
        {(flows && flows.length > 0) || (newFlow && newchat) ? (
          <WorkflowEngine
            initialFlows={flows}
            handleCreateMess={handleCreateMess}
            setMessages={setMessages}
            onReload={reload}
            messages={messages}
            isLoading={isLoading}
            
          />
          ):
          (<ChatMessages
            dataBot={dataBot}
            messages={transformedMessages}
            isLoading={isLoading}
            setInput={setInput}
            handleSubmit={handleSubmit}
            handleCreateMess={handleCreateMess}
            reload={reload}
            append={append}
            setIsAgent={setIsAgent}
            sendAgent={sendAgent}
            lead={lead}
            uuid={uuid}
            setMessages={setMessages}
            baseMessages={messages}
            closeMess={closeMess}
            setCloseMess={setCloseMess}
            flows={flows}
            setFlow={setFlow}
            flow={flow}
            messResult={messResult}
            setMessResult={setMessResult}
            isAgent={isAgent}
            newchat={newchat}
            newFlow={newFlow}
          />
        )}
          <ChatInput
            input={input}
            handleSubmit={handleSubmit}
            handleInputChange={handleInputChange}
            textPlaceholder={dataBot?.textPlaceholder}
            isLoading={isLoading}
            multiModal={
              process.env.NEXT_PUBLIC_MODEL === "gpt-4-vision-preview"
            }
            handleCreateMess={handleCreateMess}
            botId={botId}
            setFilePath={setFilePath}
            filePath={filePath}
            setFile={setFile}
            setMessages={setMessages}
            checkLead={checkLead}
            flow={flow}
            setInput={setInput}
            append={append}
            flows={flows}
            messages={messages}
            messResult={messResult}
            setMessResult={setMessResult}
            setFlow={setFlow}
            reload={reload}
            lead={lead}
            isAgent={isAgent}
          />
        </div>
      )}
    </div>
  );
}
