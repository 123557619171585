import axios from "axios";
import { useState } from "react";
import {
  generateString,
  appURL,
  pathImage,
} from "../../libs";
import { useApp } from "../../contexts/AppContext";
import { AttachIcon } from "../ui/element/attach-icon";
import { SendIcon } from "../ui/element/send-icon";
import { useTranslate } from "../../contexts/TranslateContext";
import { useWorkflow } from "../../contexts/WorkflowContext";
import ChatVoice from "./chat-voice";
import { VoiceInputButton } from "./voice-input-button";

export function ChatInput(props: any) {
  const { isAgent } = props;
  const [filePath, setFilePath] = useState();
  const { chatbot, flowUser, setFlow, flowWait } = useApp();
  const { translations } = useTranslate();
  const { dispatch } = useWorkflow()

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      props.setFile(file);
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(file.type)) {
        return;
      }
      const formData = new FormData();
      formData.append("file", file);
      await axios
        .post(`${appURL}/api/s3/upload/${chatbot?._id}`, formData)
        .then((res) => {
          setFilePath(res.data.uri);
          props.setFilePath(res.data.uri);
        })
        .catch((err) => { });
    }
  };


  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (isAgent) {
      const Array = props.messages.filter(
        (item: any) => item.content && item.content !== ""
      );

      Array.push({
        role: "user",
        content: props.input,
        id: generateString(8),
      });
      props.setMessages(Array);
      props.handleCreateMess(true, props.input, "user");
      setFilePath(undefined);
      props.setInput("");
    } else {
      // if (props.flow && props.flow?.actions?.length > 0) {
      if (flowUser) {
        // props.handleCreateMess(true, props.input, "user");
        // hanldeGetNextstep(hanldeGetFlow());
        props.handleSubmit(event);
        props.handleCreateMess(true, props.input, "user");
        setFilePath(undefined);
        setFlow(flowWait);
      } else {
        // if (filePath) {
        //   handleSubmitWithAnnotations(`${pathImage}${filePath}`)
        // }
        setFlow(undefined);
        props.handleSubmit(event);
        props.handleCreateMess(true, props.input, "user");
        setFilePath(undefined);
      }
    }

    dispatch({ type: 'SET_CURRENT_STEP', payload: 'chat' })
  };
  const CloseImage = () => {
    setFilePath(undefined);
    props.setFile(undefined);
    props.setFilePath(undefined);
  };

  const onComplete = (message: string) => {
    props.setInput(message);
    props.handleInputChange({ target: { name: "message", value: message } });
  }

  return (
    <>
      <div
        className={`h-[15%] flex items-center justify-between w-full max-w-5xl px-4 py-2 border-t border-[#f1f1f1]`}
      >
        <label className="" htmlFor="upload-image">
          <div className="upload_image">
            <AttachIcon className="w-[22px] text-gray-400" style={{}} />
            <input
              type="file"
              id="upload-image"
              name="image"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </label>
        <div className="w-full relative flex items-center">
          {filePath && (
            <div className="group">
              <button
                onClick={CloseImage}
                type="button"
                style={{ color: "#979c9e" }}
                className="absolute left-8 bottom-6 cursor-pointer opacity-0 group-hover:opacity-100"
              >
                x
              </button>
              <img
                className="h-[30px]"
                style={{ width: "30px" }}
                src={`${pathImage}${filePath}`}
              ></img>
            </div>
          )}
          <input
            autoFocus
            name="message"
            placeholder={`${props?.textPlaceholder
              ? props?.textPlaceholder
              : translations?.type_a_message
              }`}
            autoComplete="off"
            className={`w-full p-3 outline-none rounded-xl`}
            value={props.input}
            onChange={props.handleInputChange}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSubmit(e);
              }
            }}
          />

        </div>
        <div className="flex items-center">
          {chatbot?.isVoice ? (
            <ChatVoice setMessageText={onComplete} />
          ) : (
            <VoiceInputButton language={'vi-VN'} onComplete={onComplete} />

          )}
          <button
            disabled={!props.lead?.botOn}
            type="submit"
            className={`p-2 rounded-xl`}
            onClick={handleSubmit}
          >
            <SendIcon
              style={{ color: chatbot?.chatbotColor || "#3047EC" }}
              className="w-[24px] cursor-pointer"
            />
          </button>
        </div>
      </div>
    </>
  );
}
