import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import type { Product } from "../types/product"

type CartItem = Product & { quantity: number }

interface ShoppingState {
  cartItems: CartItem[],
  product: Product
}

type ShoppingAction =
  | { type: 'ADD_TO_CART'; payload: Product }
  | { type: 'REMOVE_FROM_CART'; payload: string }
  | { type: 'CLEAR_CART' }
  | { type: 'SET_PRODUCT'; payload: Product }

const initialState: ShoppingState = {
  cartItems: [],
  product: {
    id: '',
    product_name: '',
    description: '',
    price: '',
    image_url: '',
    product_url: ''
  }
}

function shoppingReducer(state: ShoppingState, action: ShoppingAction): ShoppingState {
  switch (action.type) {
    case 'ADD_TO_CART':
      const existingItem = state.cartItems.find(item => item.id === action.payload.id)
      if (existingItem) {
        return {
          ...state,
          cartItems: state.cartItems.map(item =>
            item.id === action.payload.id ? { ...item, quantity: item.quantity + 1 } : item
          )
        }
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, { ...action.payload, quantity: 1 }]
        }
      }
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter(item => item.id !== action.payload)
      }
    case 'CLEAR_CART':
      return {
        ...state,
        cartItems: []
      }
    case 'SET_PRODUCT':
      return {
        ...state,
        product: action.payload
      }
    default:
      return state
  }
}

const ShoppingContext = createContext<{
  state: ShoppingState;
  dispatch: React.Dispatch<ShoppingAction>;
} | undefined>(undefined)

export function ShoppingProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(shoppingReducer, initialState)

  return (
    <ShoppingContext.Provider value={{ state, dispatch }}>
      {children}
    </ShoppingContext.Provider>
  )
}

export function useShoppingContext() {
  const context = useContext(ShoppingContext)
  if (context === undefined) {
    throw new Error('useShoppingContext must be used within a ShoppingProvider')
  }
  return context
}

