import axios from 'axios';
import { accessToken, appURL } from '.';

export const transcribeAudio = async (audioData: Blob): Promise<string> => {
    const formData = new FormData();
    const audioFile = new File([audioData], 'recording.wav', { type: 'audio/wav' });
    formData.append('file', audioFile);
    try {
        const response = await axios.post(`${appURL}/api/transcribe`, formData, {
            headers: {
                'x-api-key': accessToken,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.text;
    } catch (error) {
        console.error('Error transcribing audio:', error);
        return "";
    }
};

export const transcribeWhisper = async (audioData: Blob): Promise<string> => {
    const formData = new FormData();
    formData.append('file', audioData);
    formData.append('model', 'whisper-1');
    formData.append('response_format', 'json');
    //formData.append('language', 'vi');
    try {
        const response = await axios.post(`https://api.openai.com/v1/audio/transcriptions/api/transcribe`, formData, {
            headers: {
                'Authorization': `Bearer ${process.env.REACT_APP_WHISPER_API_KEY}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data.text;
    } catch (error) {
        console.error('Error transcribing audio:', error);
        return "";
    }
};