import { Button } from "../ui/button"
import type { IFlow, IAction } from '../../types/workflow'
import { getButtonsFromActions, getImageFromActions } from '../../utils/workflow'
import { useWorkflow } from '../../contexts/WorkflowContext'
import { checkImageExists, replaceName } from "../../libs"
import ChatAvatar from "./chat-avatar"
import { useEffect, useState } from "react"

interface WorkflowStepProps {
  flow: IFlow;
  onAction: (action: any) => void;
  isHistory: boolean;
}

export function WorkflowStep({ flow, onAction, isHistory }: WorkflowStepProps) {
  const { state } = useWorkflow()
  const buttons = getButtonsFromActions(flow?.actions);
  const imageData = getImageFromActions(flow?.actions);

  const [validImage, setValidImage] = useState<boolean>(true);

  useEffect(() => {
    const validateImages = async () => {
      if (imageData) {
        const validation: boolean = await checkImageExists(imageData.image_url || "") as boolean;
        setValidImage(validation);
      }
    };

    validateImages();
  }, [imageData]);

  return (
    <div className="w-full mx-auto">
      <div className="space-y-4">
        <div className={`flex items-start gap-2 justify-start`}>
          <div
            style={{ backgroundColor: "#f5f6f8" }}
            className={`flex flex-col rounded p-2 order-1`}>
            {(flow?.message || flow?.actions.some(action => action.message || action.name)) && (
              <p className="text-gray-800">
                {replaceName(flow?.message, '') || flow?.actions.map(action => action.message || action.name).join(' ')}
              </p>
            )}
          </div>
          <ChatAvatar role="bot" />
        </div>

        {validImage && imageData && (
          <div className="space-y-2">
            <img
              src={imageData.image_url}
              alt={imageData.title || 'Action image'}
              className="w-full h-auto rounded-lg"
            />
            {imageData.title && (
              <p className="text-sm text-gray-600 text-center">{imageData.title}</p>
            )}
          </div>
        )}

        {!isHistory && (state.currentStep === 'workflow' || state.currentStep === 'chat') && (
          <div className="space-y-2 flex flex-col justify-end">
            {buttons.map((button, index) => {
              switch (button.type) {
                case 'postback':
                  const [message, nextStep] = button.payload.split(':');
                  return (
                    <div className="flex justify-end">
                      <Button
                        key={index}
                        className="w-auto p-2 border-gray-300 rounded-lg bg-[#e5eeff] hover:bg-blue-700 hover:text-white text-gray-600"
                        onClick={() => onAction({
                          ...button,
                          message: message,
                          data: null,
                          index,
                          next_step: nextStep === 'none' ? undefined : nextStep,
                          _id: `generated-${index}`
                        })}
                      >
                        {button.title}
                      </Button>
                    </div>
                  );
                case 'web_url':
                  return (
                    <div className="flex justify-end">
                      <Button
                        key={index}
                        className="w-auto p-2 border-gray-300 rounded-lg bg-[#e5eeff] hover:bg-blue-700 hover:text-white text-gray-600"
                        onClick={() => window.open(button.url, '_blank')}
                      >
                        {button.title}
                      </Button>
                    </div>
                  );
                case 'phone_number':
                  return (
                    <div className="flex justify-end">
                      <Button
                        key={index}
                        className="w-auto p-2 border-gray-300 rounded-lg bg-[#e5eeff] hover:bg-blue-700 hover:text-white text-gray-600"
                        onClick={() => window.location.href = `tel:${button.payload}`}
                      >
                        {button.title}
                      </Button>
                    </div>
                  );
              }
            })}
          </div>
        )}
      </div>
    </div>
  )
}



