import ChannelComponent from "./ChannelComponent";
import PromptComponent from "./PromptComponent";
import FlowComponent from "./FlowComponent";
import { replaceName } from "../../../libs";
import Markdown from "react-markdown";
import { useApp } from "../../../contexts/AppContext";
import { useTranslate } from "../../../contexts/TranslateContext";
import { getButtonsFromActions } from '../../../utils/workflow'
const renderers = {
  a: (props) => {
    let { href, children } = props;
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  },
};
const InitComponent = ({
  chatbot,
  flows,
  setPrompt,
  setFlow,
  handleMessages,
  setMessages,
  messages,
}) => {
  const { setCurrentPage } = useApp();
  const {translations} = useTranslate();
  const buttons = getButtonsFromActions(flows[0]?.actions);
  return (
    <div className="w-full text-[#303437] relative">
      <div className="w-full pl-4">
        <p className="text-[20px] font-bold text-white">
          <Markdown className="break-words" components={renderers}>
            {replaceName("Hello @name 👋", chatbot?.lead?.name ?? "")}
          </Markdown>
        </p>
        <p className="text-white">{chatbot?.welcomeMessage ? replaceName(chatbot?.welcomeMessage, chatbot?.lead?.name ?? "") : `I'm your AI Assistant. How can I help you?`}</p>
      </div>
      <div className="pt-4">
        <ChannelComponent
          chatbot={chatbot}
          handleMessages={handleMessages}
          setNewChat={() => setCurrentPage('messages')}
          setFlow={setFlow}
          setPrompt={setPrompt}
          messages={messages}
        />
      </div>
      {/* <div className="pt-4">
        <TicketComponent chatbot={chatbot} />
      </div> */}
      {flows && flows.length > 0 ? (
        <div className="pt-4 ">
          <FlowComponent
            buttons={buttons}
            flows={flows}
            setNewChat={() => setCurrentPage('chat')}
            setFlow={setFlow}
            setPrompt={setPrompt}
            setMessages={setMessages}
            handleMessages={handleMessages}
            title={translations?.find_for_help}
          />
        </div>
      ) : (
        chatbot?.prompts && chatbot?.prompts.length > 0 && (
          <div className="pt-4 ">
            <PromptComponent
              chatbot={chatbot}
              setNewChat={() => setCurrentPage('chat')}
              setPrompt={setPrompt}
              setFlow={setFlow}
              setMessages={setMessages}
            />
          </div>
        )
      )}

    </div>
  );
};
export default InitComponent;
