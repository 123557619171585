import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from './contexts/AppContext';
import { ShoppingProvider } from "./contexts/ShoppingContext";
import { TranslateProvider } from "./contexts/TranslateContext";
import { WorkflowProvider } from './contexts/WorkflowContext'
const root = ReactDOM.createRoot(
  document.getElementById("taggoai") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AppProvider>
      <TranslateProvider>
        <WorkflowProvider>
          <ShoppingProvider>
            <App />
          </ShoppingProvider>
        </WorkflowProvider>
      </TranslateProvider>
    </AppProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
