import React from 'react'
import { cn } from "../../libs/utils"

export function AIThinkingIndicator() {
  return (
    <div className="flex items-center space-x-2 animate-pulse">
      <div className="w-2 h-2 bg-gray-500 rounded-full"></div>
      <div className="w-2 h-2 bg-gray-500 rounded-full animation-delay-200"></div>
      <div className="w-2 h-2 bg-gray-500 rounded-full animation-delay-400"></div>
    </div>
  )
}

