import { Product } from "../../../../types/product";
import { AddToCart } from "./add-to-cart";
import { useShoppingContext } from "../../../../contexts/ShoppingContext";
import { useApp } from "../../../../contexts/AppContext";
import { useEffect, useState } from "react";
import { checkImageExists, cleanPrice, formatPrice } from "../../../../libs";
import ChatAvatar from "../../chat-avatar";

export function ProductCard({
    products,
}: {
    products: Product[];
}) {
    const { state: { cartItems }, dispatch } = useShoppingContext()
    const { chatbot } = useApp()
    const [validImages, setValidImages] = useState<Record<number, boolean>>({});

    useEffect(() => {
        const validateImages = async () => {
            const validations: Record<number, boolean> = {};
            await Promise.all(
                products?.map(async (product: Product, index: number) => {
                    validations[index] = await checkImageExists(product.image_url || "") as boolean;
                })
            );
            setValidImages(validations);
        };
        if (products?.length > 0) {
            validateImages();
        }
    }, [products]);

    const handleProductClick = (product: Product) => {
        dispatch({ type: 'SET_PRODUCT', payload: product })
    };

    // const addToCart = (product: Product) => {
    //     dispatch({ type: 'ADD_TO_CART', payload: product })
    // }
    if(products?.length === 0) return "";
    return (
        <div className={`flex items-start gap-2 justify-start mb-3`}>
            <div className={`flex flex-col rounded order-1`}>
                <div className="grid grid-cols-2 gap-3 mt-3 mx-2">
                    {products?.length > 0 && products?.map((product: Product, index: number) => (
                        <div
                            key={index}
                            className="bg-white rounded-lg p-2 shadow-sm cursor-pointer"
                            onClick={() => handleProductClick(product)}
                        >
                            {validImages[index] && product.image_url && (
                                <img
                                    src={product.image_url}
                                    alt={product.product_name}
                                    className="w-full h-auto rounded-lg mb-2"
                                />
                            )}
                            <div className="space-y-1">
                                <div className="flex justify-between items-center">
                                    <p className="text-sm font-semibold">{formatPrice(cleanPrice(product.price))} {chatbot.currency}</p>
                                </div>
                                <p className="text-xs text-gray-600 line-clamp-2">{product.product_name}</p>

                                {chatbot.isEcommerce && <AddToCart product={product} />}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ChatAvatar role="bot" />
        </div>
    )
}