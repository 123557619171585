type MediaStreamType = MediaStream | null;

let mediaRecorder: MediaRecorder;
let audioChunks: BlobPart[] = [];

let media: MediaStreamType = null

export const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(stream => {
            media = stream;
            mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.start();
            mediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
        });
};

export const stopRecordingAndProcess = (): Promise<Blob> => {
    return new Promise((resolve) => {
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
        audioChunks = [];
        resolve(audioBlob);
      };
      stopMediaStream();
      mediaRecorder.stop();
    });
  };


const stopMediaStream = () => {
    if (media) {
      const tracks = media.getTracks();
      tracks.forEach((track) => track.stop());
      media = null;
    }
};  
