'use client'

import React, { createContext, useContext, useReducer, ReactNode } from 'react'
import type { IAction, IFlow } from '../types/workflow'

interface WorkflowState {
  currentFlow: IFlow | null;
  history: IFlow[];
  flows: IFlow[];
  currentStep: 'workflow' | 'chat';
}

type WorkflowAction = 
  | { type: 'SET_FLOWS'; payload: IFlow[] }
  | { type: 'SET_CURRENT_FLOW'; payload: IFlow }
  | { type: 'ADD_TO_HISTORY'; payload: IFlow }
  | { type: 'SET_CURRENT_STEP'; payload: WorkflowState['currentStep'] }
  | { type: 'RESET_WORKFLOW' };

const initialState: WorkflowState = {
  currentFlow: null,
  history: [],
  flows: [],
  currentStep: 'workflow'
}

function workflowReducer(state: WorkflowState, action: WorkflowAction): WorkflowState {
  switch (action.type) {
    case 'SET_FLOWS':
      return {
        ...state,
        flows: action.payload,
        currentFlow: action.payload.find(flow => flow.index === 0) || null
      }
    case 'SET_CURRENT_FLOW':
      return {
        ...state,
        currentFlow: action.payload
      }
    case 'ADD_TO_HISTORY':
      return {
        ...state,
        history: [...state.history, action.payload]
      }
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep: action.payload
      }
    case 'RESET_WORKFLOW':
      return {
        ...state,
        currentFlow: state.flows.find(flow => flow.index === 0) || null,
        history: [],
        currentStep: 'workflow'
      }
    default:
      return state
  }
}

const WorkflowContext = createContext<{
  state: WorkflowState;
  dispatch: React.Dispatch<WorkflowAction>;
} | undefined>(undefined)

export function WorkflowProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(workflowReducer, initialState)

  return (
    <WorkflowContext.Provider value={{ state, dispatch }}>
      {children}
    </WorkflowContext.Provider>
  )
}

export function useWorkflow() {
  const context = useContext(WorkflowContext)
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider')
  }
  return context
}