import { ChatbotType } from "@/types/chatbot";
import { generateString } from "../../../libs";
import { Button } from "../../ui/element/button";
import { isMobile } from "react-device-detect";
interface SuggestedToolsProps {
  suggested: { data: string[] };
  setMessages: (messages: any[]) => void;
  handleCreateMess: (flag: boolean, message: string, role: string) => void;
  reload: () => void;
  chatbot: ChatbotType;
}

export const SuggestedTool: React.FC<SuggestedToolsProps> = ({
  suggested,
  setMessages,
  handleCreateMess,
  reload,
  chatbot,
}) => {
  const handleCreate = (message: string) => {
    const array = suggested.data.filter(
      (item) => item && item.trim() !== ""
    );
    const arrays = [
      ...array,
      {
        role: "user",
        content: message,
        id: generateString(8),
      },
    ];

    setMessages(arrays);
    handleCreateMess(true, message, "user");
    reload();
  };

  return (
    <div className={`flex justify-end m-2 text-sm p-2`}>
      <div>
        {suggested?.data.map((text: string, index: number) => (
          <div key={index} className={`mb-2 flex justify-end`}>
            <Button onClick={() => handleCreate(text)} label={text} />
          </div>
        ))}
      </div>
    </div>
  );
};
